var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "flex items-center w-full cell-renderer-select-settings-container",
    },
    [
      _vm.hasSingleOptions
        ? _c("v-select", {
            attrs: {
              value: _vm.selectedSettings,
              clearable: false,
              "append-to-body": "",
              options: _vm.settingsOptions,
            },
            on: { input: _vm.setSelectedSetting },
          })
        : _vm._e(),
      _vm.hasMultipleOptions
        ? _c("v-select", {
            attrs: {
              value: _vm.selectedSettings,
              multiple: "",
              "append-to-body": "",
              options: _vm.settingsOptions,
            },
            on: { input: _vm.setSelectedSetting },
          })
        : _vm._e(),
      _vm.isText
        ? _c("vs-input", {
            staticClass: "w-full",
            attrs: { value: _vm.inputValue },
            on: { change: _vm.setSetting },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }